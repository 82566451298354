import API from '../utils/constantes/API';
import { PREFIX_BACK } from '../common/Configuration';
import { TYPE_DOSSIER } from '../common/constants';
import { fetch, uploadFiles } from './ResourcesService';
import { isParticipationVolontaireEnabled } from './TenantService';

export const isAssurance = (type) => type === TYPE_DOSSIER.ASSURANCE;

const dossierUrl = `${PREFIX_BACK}dossiers/`;

const putDossier = (uid, values, urlPart) =>
  fetch(true, `${dossierUrl}${uid}/${urlPart}`, {
    method: 'PUT',
    body: JSON.stringify(values)
  });
const putDossierElement = (uid, file, urlPart) => uploadFiles(true, `${dossierUrl}${uid}/${urlPart}`, 'POST', file);
const deleteDossierElement = (uid, nom, urlPart) => fetch(true, `${dossierUrl}${uid}/${urlPart}?nom=${nom}`, { method: 'DELETE' });

export const getDossier = (uid) => fetch(true, dossierUrl + uid);
export const getDossierByUidEntreprise = (uidEntreprise) => fetch(true, `${dossierUrl}search?uidEntreprise=${uidEntreprise}`);

export const mettreAjourParticipation = (nbSalaries, uid, { valuesAenvoye }) =>
  putDossier(uid, valuesAenvoye, `participation${nbSalaries < 50 && isParticipationVolontaireEnabled() ? 'Volontaire' : ''}`);
export const mettreAjourFormuleParticipation = (uid, { valuesAenvoye }) =>
  putDossier(uid, valuesAenvoye, 'formuleParticipationVolontaire');
export const mettreAjourGarantiesPrevoyance = (uid, { valuesAenvoyer }) => putDossier(uid, valuesAenvoyer, 'garantiesPrevoyance');
export const mettreAjourAbondementUnilateral = (uid, { valuesAenvoye }) => putDossier(uid, valuesAenvoye, 'abondementUnilateral'); //TODO: supprimer lors de la refacto epargne/perob
export const mettreAjourEmailDirigeant = (uid, { valuesAenvoye }) => putDossier(uid, valuesAenvoye, 'adresseEmailDirigeant');
export const mettreAjourModalitesPero = (uid, { valuesAenvoyer }) => putDossier(uid, valuesAenvoyer, 'modalitesPero');
export const mettreAjourInteressement = (uid, { valuesAenvoye }) => putDossier(uid, valuesAenvoye, 'interessement');
export const mettreAjourPlanExistant = (uid, { valuesAenvoye }) => putDossier(uid, valuesAenvoye, 'planExistant');
export const mettreAjourTarification = (uid, { valuesAenvoye }) => putDossier(uid, valuesAenvoye, 'tarification');
export const mettreAjourFraisContrat = (uid, { valuesAenvoye }) => putDossier(uid, valuesAenvoye, 'fraisContrat');
export const mettreAjourAbondements = (uid, { dispositifs }) => putDossier(uid, dispositifs, 'abondements');
export const sendKycFields = (uid, fields) => putDossier(uid, fields, 'donneesKYC');

export const stockerPagesConsultationEntreprise = (uid, files) => putDossierElement(uid, files, 'consultationEntreprise');
export const stockerPagesDocumentPlanExistant = (uid, files) => putDossierElement(uid, files, 'documentPlanExistant');
export const stockerCasParticuliers = (uid, files) => putDossierElement(uid, files, 'justificatifsCasParticuliers');
export const stockerPagesListeBeneficiaires = (uid, files) => putDossierElement(uid, files, 'listeBeneficiaires');
export const stockerPiecesKyc = (uid, files) => putDossierElement(uid, files, 'justificatifKYC');
export const stockerRib = (uid, files) => putDossierElement(uid, files, 'rib');

export const supprimerPageConsultationEntreprise = (uid, nom) => deleteDossierElement(uid, nom, 'consultationEntreprise');
export const supprimerPageDocumentPlanExistant = (uid, nom) => deleteDossierElement(uid, nom, 'documentPlanExistant');
export const supprimerCasParticulier = (uid, nom) => deleteDossierElement(uid, nom, 'justificatifsCasParticuliers');
export const supprimerPageListeBeneficiaires = (uid, nom) => deleteDossierElement(uid, nom, 'listeBeneficiaires');
export const supprimerPiecesKyc = (uid, nom) => deleteDossierElement(uid, nom, 'justificatifKYC');
export const supprimerRib = (uid, nom) => deleteDossierElement(uid, nom, 'rib');

export const transmettreDossier = (uid, typeDossier) =>
  fetch(true, `${dossierUrl}${uid}/transmettre${isAssurance(typeDossier) ? 'assurance' : ''}`, {
    method: 'POST'
  });

export const envoiEmailDocumentationPEROB = (uidUtilisateur) =>
  fetch(true, `${PREFIX_BACK}dossiers/${uidUtilisateur}/envoiEmailDocumentationPEROB`, {
    method: 'POST'
  });

export const envoiEmailBulletinAvantSignature = (uid) =>
    fetch(true, `${PREFIX_BACK}dossiers/${uid}/mail-bulletin-avant-signature`, {
        method: 'POST'
    });

export const updateCategoriesSalaries = (uid, collegeFormValues) =>
  fetch(true, `${PREFIX_BACK}dossiers/${uid}/categoriesSalaries`, {
    method: 'PUT',
    body: JSON.stringify(collegeFormValues)
  });
