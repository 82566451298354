import {TYPE_VERSEMENT_TARIFICATION} from "../../../../../common/constants";


export const getVersementDisponible = (dispositifPei, dispositifPercoi) => {
    const versements = [TYPE_VERSEMENT_TARIFICATION.VERSEMENT_VOLONTAIRE];
    if (
        (dispositifPei && dispositifPei.abondement) ||
        (dispositifPercoi && dispositifPercoi.abondement) ||
        (dispositifPercoi.abondementUnilateral?.miseEnPlace)
    ) {
        if (dispositifPei && dispositifPei.abondement) {
            dispositifPei.abondement.sourceVersements.forEach(versement => {
                if (!versements.includes(versement)) {
                    versements.push(versement);
                }
            })
        }
        if (dispositifPercoi && dispositifPercoi.abondement) {
            dispositifPercoi.abondement.sourceVersements.forEach(versement => {
                if (!versements.includes(versement)) {
                    versements.push(versement);
                }
            })
        }
        versements.push(TYPE_VERSEMENT_TARIFICATION.ABONDEMENT);
    }
    return versements;
};
