/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import EmployeeCompanySelector from './EmployeeCompanySelector';
import RepartitionFees from './RepartitionFees';
import { YesChoix } from '../../../../form/Choix';
import { requiredValidator } from '../../../../../common/Validators';
import { sourcesVersementsPercoi } from '../../../../../services/SourcesVersementsService';
import { TYPE_VERSEMENT_TARIFICATION } from '../../../../../common/constants';

const EpargneFeesDistribution = ({ optionSelected, selectables = [] }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="fees-distrib-option">
        <Field
          name="optionChargeFrais"
          component={YesChoix}
          type="radio"
          value="1"
          label={t('ges-tarification.fraisEntree.question.cibleFrais1')}
          id={'1'}
          validate={requiredValidator}
        />
        {optionSelected === '1' && <EmployeeCompanySelector target="ciblePrelevements" disabled={optionSelected !== '1'} />}
        <div className="ges-tarification-inline-display" />
      </div>
      <div className="fees-distrib-option">
        <Field
          name="optionChargeFrais"
          component={YesChoix}
          type="radio"
          value={'2'}
          label={t('ges-tarification.fraisEntree.question.cibleFrais2')}
          id={'2'}
          validate={requiredValidator}
        />
        {optionSelected === '2' && (
          <>
            <EmployeeCompanySelector
              label={t('ges-tarification.fraisEntree.question.cibleFrais2.versementsVolontaires')}
              target="versementsVolontaires"
              disabled={
                optionSelected !== '2' || !selectables || !selectables.includes(TYPE_VERSEMENT_TARIFICATION.VERSEMENT_VOLONTAIRE)
              }
            />
            <EmployeeCompanySelector
              label={t('ges-tarification.fraisEntree.question.cibleFrais2.participation')}
              target="participation"
              disabled={optionSelected !== '2' || !selectables || !selectables.includes(TYPE_VERSEMENT_TARIFICATION.PARTICIPATION)}
            />
            <EmployeeCompanySelector
              label={t('ges-tarification.fraisEntree.question.cibleFrais2.interessement')}
              target="interessement"
              disabled={optionSelected !== '2' || !selectables || !selectables.includes(TYPE_VERSEMENT_TARIFICATION.INTERESSEMENT)}
            />
            <EmployeeCompanySelector
              label={t('ges-tarification.fraisEntree.question.cibleFrais2.versementsComplementaires')}
              target="versementsComplementaires"
              disabled={optionSelected !== '2' || !selectables || !selectables.includes(TYPE_VERSEMENT_TARIFICATION.ABONDEMENT)}
            />
            <EmployeeCompanySelector
              label={t('ges-tarification.fraisEntree.question.cibleFrais2.versementCET')}
              target="versementCET"
              disabled={optionSelected !== '2' || !selectables || !selectables.includes(TYPE_VERSEMENT_TARIFICATION.JOURS_CET)}
            />
          </>
        )}
      </div>
      <div className="fees-distrib-option">
        <Field
          name="optionChargeFrais"
          component={YesChoix}
          type="radio"
          value={'3'}
          label={t('ges-tarification.fraisEntree.question.cibleFrais3')}
          id={'3'}
          validate={requiredValidator}
        />
        {optionSelected === '3' && <RepartitionFees disabled={optionSelected !== '3'} />}
        <p className="ges-libelle-tarification" />
      </div>
    </>
  );
};

EpargneFeesDistribution.propTypes = {
  optionSelected: PropTypes.string,
  selectables: PropTypes.arrayOf(PropTypes.string)
};

export default EpargneFeesDistribution;
